<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div v-if="medias">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form>
              <b-row>
                <b-col
                    cols="12"
                >
                  <h2>بارگذاری مدیای جدید (ویدیو - فایل)</h2>
                  <b-button
                      class="mt-2 col-6"
                      variant="warning"
                      @click="$refs.refInputEl2.click()"
                  >
                    <input
                        ref="refInputEl2"
                        type="file"
                        class="d-none"
                        @change="select"
                    >
                    <span class="">بارگذاری</span>
                  </b-button>
                  <b-button
                      variant="primary"
                      class="mt-2 mb-sm-0 ml-2 col-12 col-md-2"
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      @click="upload"
                  >
                    ثبت
                  </b-button>
                </b-col>
              </b-row>
            </b-form>

            <b-progress
                v-if="uploaded !== 0 && uploaded !== 100"
                :value="uploaded"
                max="100"
                variant="primary"
                class=" mt-2"
            />

            <b-alert
                :show="successAlert"
                variant="success"
                class="col-md-2 col-12"
            >
              <div class="alert-body mt-2">
                <feather-icon
                    class="mr-25"
                    icon="CheckIcon"
                />
                <span class="ml-25">فایل شما آپلود شده است.</span>
              </div>
            </b-alert>
            <b-alert
                :show="errorAlert"
                variant="danger"
                class="col-md-2 col-12"
            >
              <div class="alert-body mt-2">
                <feather-icon
                    class="mr-25"
                    icon="CloseIcon"
                />
                <span class="ml-25">آپلود فایل با مشکل مواجه شد!</span>
              </div>
            </b-alert>
          </b-card>

          <div>
            <b-card v-if="medias.videos.length > 0">
              <b-row class="px-2">

                <h3>ویدیو‌ها</h3>
                <app-collapse accordion class="col-12">

                  <!--              <app-collapse-item v-for="(video,item) in medias.videos" :title="'ویدیو شماره '+`${item+1}`">-->
                  <app-collapse-item v-for="(video,item) in medias.videos" :title="video.title.split('.')[0]">
                    <video
                        class="col-12"
                        controls
                        style="max-height: 30rem;"
                    >
                      <source
                          size=""
                          :src="baseURL+video.url"
                          type=""
                      />
                    </video>

<!--                    <b-button-->
<!--                        v-if="video.arvanCloudVideoId === null"-->
<!--                        class="m-2"-->
<!--                        variant="primary"-->
<!--                        @click="arvanObj.mediaId = video.mediaId"-->
<!--                        v-b-modal.modal-arvan-->
<!--                    >-->
<!--                      <feather-icon icon="UnlockIcon" size="20" class=""/>-->
<!--                    </b-button>-->

<!--                    <b-button-->
<!--                        v-else-->
<!--                        class="m-2"-->
<!--                        variant="primary"-->
<!--                    >-->
<!--                      <span v-if="video.aravanStatus === 'queue_download'">در صف دانلود ابرآروان</span>-->
<!--                      <span v-if="video.aravanStatus === 'queue_convert'">در صف تبدیل</span>-->
<!--                      <span v-if="video.aravanStatus === 'converting'">در حال تبدیل</span>-->
<!--                      <span v-if="video.aravanStatus === 'complete'">-->
<!--                        <feather-icon icon="LockIcon" size="20" class=""/>-->
<!--                        <span class="ml-1">محافظت شده</span>-->
<!--                      </span>-->
<!--                    </b-button>-->

                    <b-button
                        class="m-2"
                        variant="danger"
                        v-b-modal.modal-video-delete
                        @click="showVideoDeleteModal(video)"
                    >
                      <feather-icon icon="TrashIcon" size="20" class=""/>
                    </b-button>

                    <b-button
                        class="m-2"
                        variant="warning"
                        @click="showChangeNameModal(video.mediaId,video.title.split('.')[0])"
                        v-b-modal.modal-edit-name
                    >
                      <feather-icon icon="EditIcon" size="20"/>
                    </b-button>

                    <b-button
                        class="m-2"
                        variant="success"
                        v-b-modal.modal-edit-priority
                        @click="showChangePriorityModal(video.mediaId)"
                    >
                      <feather-icon icon="AlignCenterIcon" size="20"/>
                    </b-button>
                  </app-collapse-item>

                </app-collapse>

              </b-row>
            </b-card>

            <b-card v-if="medias.pdFs.length > 0">
              <b-row class="px-2">

                <h3>فایل ها</h3>
                <div v-for="(file,index) in medias.pdFs" class="col-12">

                  <div class="row ">
                    <div class="col-md-4 mt-3">

                      <a class="d-block" :href="baseURL+file.url" download
                         target="_blank">{{ file.title.split('.')[0] }}</a>

                      <b-button
                          class=""
                          variant="danger"
                          v-b-modal.modal-delete
                          @click="showModal(file.mediaId)"
                      >
                        <feather-icon icon="TrashIcon" size="20" class=""/>
                      </b-button>

                      <b-button
                          class="m-2"
                          variant="primary"
                          @click="showChangeNameModal(file.mediaId,file.title.split('.')[0])"
                          v-b-modal.modal-edit-name
                      >
                        <feather-icon icon="EditIcon" size="20"/>
                      </b-button>

                      <b-button
                          class=""
                          variant="success"
                          v-b-modal.modal-edit-priority
                          @click="showChangePriorityModal(file.mediaId)"
                      >
                        <feather-icon icon="AlignCenterIcon" size="20"/>
                      </b-button>

                    </div>
                  </div>
                </div>
              </b-row>
            </b-card>

            <b-card v-if="medias.podcasts.length > 0">
              <b-row class="px-2">

                <h3>پادکست ها</h3>
                <app-collapse accordion class="col-12">

                  <app-collapse-item v-for="(podcast,item) in medias.podcasts" :title="podcast.title.split('.')[0]">
                    <audio class="col-12" controls crossorigin playsinline>
                      <source
                          :src="baseURL+podcast.url"
                          type=""
                      />
                    </audio>
                    <b-button
                        class="m-2"
                        variant="danger"
                        v-b-modal.modal-delete
                        @click="showModal(podcast.mediaId)"
                    >
                      <feather-icon icon="TrashIcon" size="20" class=""/>
                    </b-button>

                    <b-button
                        class="m-2"
                        variant="primary"
                        @click="showChangeNameModal(podcast.mediaId,podcast.title.split('.')[0])"
                        v-b-modal.modal-edit-name
                    >
                      <feather-icon icon="EditIcon" size="20"/>
                    </b-button>

                    <b-button
                        class="m-2"
                        variant="success"
                        v-b-modal.modal-edit-priority
                        @click="showChangePriorityModal(podcast.mediaId)"
                    >
                      <feather-icon icon="AlignCenterIcon" size="20"/>
                    </b-button>
                  </app-collapse-item>

                </app-collapse>

              </b-row>
            </b-card>

            <b-card v-if="medias.images.length > 0">
              <b-row class="px-2">

                <h3>عکس ها</h3>
                <app-collapse accordion class="col-12">

                  <div class="row ">

                    <div v-for="(image,index) in medias.images" class="col-md-4 mt-3">

                      <img class="col-12" style="height: 400px"
                           :src="baseURL+image.url" alt="profile">
                      <b-button
                          class="col-4 m-2"
                          variant="danger"
                          v-b-modal.modal-delete
                          @click="showModal(image.mediaId)"
                      >
                        <feather-icon icon="TrashIcon" size="20" class=""/>
                      </b-button>

                      <b-button
                          class="col-4 m-2"
                          variant="success"
                          v-b-modal.modal-edit-priority
                          @click="showChangePriorityModal(image.mediaId)"
                      >
                        <feather-icon icon="AlignCenterIcon" size="20"/>
                      </b-button>

                    </div>

                  </div>

                </app-collapse>

              </b-row>
            </b-card>

          </div>
        </b-col>
      </b-row>
    </div>
    <b-modal
        id="modal-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="deleteMedia(deleteItem)"
    >
      <span>حذف شود؟</span>
    </b-modal>
    <b-modal
        id="modal-video-delete"
        centered
        ok-title="حذف"
        cancelTitle="انصراف"
        @ok="sendMediaRemoveRequest()"
    >
      <span>حذف شود؟</span>
    </b-modal>
    <b-modal
        id="modal-edit-name"
        title="ویرایش نام فایل"
        centered
        ok-title="ثبت"
        cancelTitle="انصراف"
        @ok="updateMediaName"
    >
      <input @keydown.enter="updateMediaName" type="text" v-model="newName">
    </b-modal>
    <b-modal
        id="modal-edit-priority"
        title="ویرایش اولویت فایل"
        centered
        ok-title="ثبت"
        cancelTitle="انصراف"
        @ok="updateMediaPriority"
    >
      <input @keydown.enter="updateMediaPriority" type="number" v-model="newPriority">
    </b-modal>
    <b-modal
        id="modal-arvan"
        title="محافظت از فایل"
        centered
        ok-title="ثبت"
        cancelTitle="انصراف"
        @ok="arvanCreate"
    >
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <b-row>
          <span class="mx-1 mb-1">عنوان :</span>
          <b-col cols="12">
            <validation-provider
                name="title"
                rules="required"
            >
              <b-form-input id="title" v-model="arvanObj.title" class="form-control"></b-form-input>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia, BOverlay,
  BRow, BTab, BTabs, BProgress, BModal
} from "bootstrap-vue";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vSelect from "vue-select";
import axios from "axios";
import {getUserData} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {ProductGetProductsMediasForAdminRequest} from "@/libs/Api/Product";
import {MediaDeleteByPictureUrlRequest} from "@/libs/Api/Product";
import {MediaChangeMediaNameRequest, MediaChangePriorityRequest} from "@/libs/Api/Media";
import Helper from "@/libs/Helper";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {ArvanCloudVideoCreateRequest, DeleteArvanVideo} from "@/libs/Api/ArvanCloud";
import {BlogGetMediasForAdminRequest} from "@/libs/Api/Blog";

export default {
  title: 'مدیای بلاگ - پنل ادمین مکس ',
  name: "ECommerceAddProductMedia",
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BProgress,
    AppCollapse,
    AppCollapseItem,
    BModal,
    ValidationProvider,
    ValidationObserver,
  },
  // watch: {
  //   chunks(n, o) {
  //     if (n.length > 0) {
  //       this.upload();
  //     }
  //   }
  // },
  data() {
    return {
      blogId: this.$route.params.id,
      userToken: getUserData().data.token,
      medias: null,
      errorAlert: false,
      overlay: true,
      deleteItem: null,
      baseURL: Helper.baseUrl,
      types: [
        {name: 'دوره', value: 1},
        {name: 'لایو', value: 2},
        {name: 'فایل', value: 3},
        {name: 'پادکست', value: 4},
        {name: 'پست آموزشی', value: 5},
      ],
      file: null,
      chunks: [],
      uploaded: 0,
      namePictureURL: null,
      priorityPictureURL: null,
      newName: '',
      newPriority: '',
      arvanObj: {
        title: '',
        description: '',
        mediaId: null
      },
      successAlert:false,
      tempVideoObject:null
    };
  },
  async created() {
    await this.getBlogMedias();
  },
  computed: {
    formData() {
      let formData = new FormData;

      if (this.file !== null) {
        formData.set('file', this.file, `${this.file.name}`);
      }
      return formData;
    },
    config() {
      return {
        method: 'POST',
        data: this.formData,
        url: Helper.baseUrl+'api/Blog/CreateMediasForAdmin?blogId=' + this.blogId ,
        headers: {
          'Content-Type': 'application/octet-stream',
          'Authorization': this.userToken,
        },
        onUploadProgress: progressEvent => {
          this.uploaded = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }
      };
    },
  },
  methods: {
    async getBlogMedias() {
      let _this = this;
      _this.overlay = true;

      let data = {
        blogId:_this.blogId
      }

      let blogGetMediasForAdminRequest = new BlogGetMediasForAdminRequest(_this);
      blogGetMediasForAdminRequest.setParams(data)
      await blogGetMediasForAdminRequest.fetch(function (content) {
        _this.overlay = false;
        _this.medias = content;
      }, function (content) {
        console.log(content)
      });
    },
    showModal(param) {
      let _this = this;
      _this.deleteItem = param;
    },
    showVideoDeleteModal(param) {
      let _this = this;
      _this.tempVideoObject = param
    },
    showChangeNameModal(param1, param2) {
      let _this = this;
      _this.namePictureURL = param1;
      _this.newName = param2
    },
    showChangePriorityModal(param1) {
      let _this = this;
      _this.priorityPictureURL = param1;
      _this.newPriority = ''
    },
    select(event) {
      this.file = event.target.files.item(0);
      // this.createChunks();
    },
    upload() {
      axios(this.config).then(response => {

        this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `فایل با موفقیت آپلود شد.`,
          },
        })
        this.successAlert = true;
        window.location.reload();
      }).catch(error => {
        this.errorAlert = true;
        console.log(error);
      });
    },
    async arvanCreate() {
      let _this = this;
      _this.overlay = true;

      if (_this.arvanObj.title.length === 0) {
        return _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عنوان فایل الزامی است.`,
          },
        })
      }

      let arvanCloudVideoCreateRequest = new ArvanCloudVideoCreateRequest(_this);
      arvanCloudVideoCreateRequest.setParams(_this.arvanObj);
      await arvanCloudVideoCreateRequest.fetch(function (content) {
        _this.overlay = false
        if (content.isSuccess){
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `درخواست شما ارسال شد.`,
            },
          })
        }else {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات ناموفق`,
              icon: 'CloseIcon',
              variant: 'danger',
              text: `عملیات انجام نشد.`,
            },
          })
        }
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        console.log(error)
      })
    },
    async deleteMedia(param) {
      let _this = this;
      _this.overlay = true

      let mediaDeleteByPictureUrlRequest = new MediaDeleteByPictureUrlRequest(_this);
      mediaDeleteByPictureUrlRequest.setSocialMediaId(param);
      await mediaDeleteByPictureUrlRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async deleteVideoMedia() {
      let _this = this;
      _this.overlay = true

      let mediaDeleteByPictureUrlRequest = new MediaDeleteByPictureUrlRequest(_this);
      mediaDeleteByPictureUrlRequest.setSocialMediaId(_this.tempVideoObject.mediaId);
      await mediaDeleteByPictureUrlRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `حذف شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async updateMediaName() {
      let _this = this;
      _this.overlay = true

      let data = {
        socialMediaId: _this.namePictureURL,
        newName: _this.newName
      }

      let mediaChangeMediaNameRequest = new MediaChangeMediaNameRequest(_this);
      mediaChangeMediaNameRequest.setParams(data);
      await mediaChangeMediaNameRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async updateMediaPriority() {
      let _this = this;
      _this.overlay = true

      let data = {
        socialMediaId: _this.priorityPictureURL,
        priority: _this.newPriority
      }

      let mediaChangePriorityRequest = new MediaChangePriorityRequest(_this);
      mediaChangePriorityRequest.setParams(data);
      await mediaChangePriorityRequest.fetch(function (content) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        window.location.reload();
      }, function (error) {
        _this.overlay = false
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CloseIcon',
            variant: 'danger',
            text: `عملیات انجام نشد.`,
          },
        })
        console.log(error)
      })
    },
    async sendMediaRemoveRequest() {
      if (!this.tempVideoObject || !this.tempVideoObject.arvanCloudVideoId) {
        try {
          await this.deleteVideoMedia()
        } catch (error) {
          console.error(error);
        } finally {
          this.overlay = false;
        }
      } else {
        await this.sendDeleteArvanVideo();
      }
    },
    async sendDeleteArvanVideo() {
      try {
        this.overlay = true;
        let _this = this;
        let deleteArvanVideo = new DeleteArvanVideo(_this);
        deleteArvanVideo.setRequestParam({
          videoId: _this.tempVideoObject.arvanCloudVideoId,
        });
        await deleteArvanVideo.delete((response) => {
          if (response.isSuccess) {
            _this.deleteVideoMedia();
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
  }
}
</script>

<style scoped>

</style>