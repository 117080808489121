import WebServiceRequest from '../Api/WebServiceRequest'

class ProductCategoryCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductCategory/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCategoryDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('ProductCategory/Delete?id='+id)
    }
}
class ProductCategoryGetAllCourseCategoriesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('ProductCategory/GetAllCourseCategories?pageNumber='+data.pageNumber+'&count='+data.count+'')
    }
}
class ProductCategoryGetAllProductCategoriesRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('ProductCategory/GetAllProductCategories?pageNumber='+data.pageNumber+'&count='+data.count+'')
    }
}
class ProductCategoryGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('ProductCategory/GetAll?pageNumber='+data.pageNumber+'&count='+data.count+'')
    }
}
class ProductCategoryGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('ProductCategory/Get?productCategoryId='+id)
    }
}
class ProductCategoryPropertyCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('CategoryProperty/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCategoryPropertyDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('CategoryProperty/Delete?id='+id)
    }
}
class ProductCategoryPropertyGetByCategoryIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('CategoryProperty/GetByCategoryId?categoryId='+id)
    }
}
class ProductCategoryPropertyGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('CategoryProperty/Get?categoryPropertyId='+id)
    }
}
class ProductCategoryPropertyUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('CategoryProperty/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCategoryUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductCategory/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCommentCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductComment/Create')
        super.setRequestParam('isDeleted',false)
    }
    setProductId(productId){
        super.setRequestParam('productId',productId)
    }
    setMessage(message){
        super.setRequestParam('message',message)
    }
    setParentId(parentId){
        super.setRequestParam('parentId',parentId)
    }
    setProductCommentId(productCommentId){
        super.setRequestParam('productCommentId',productCommentId)
    }
}
class ProductCommentDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('ProductComment/Delete?productCommentId='+id)
    }
}
class ProductCommentGetAllAsyncByProductIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('ProductComment/GetAllAsyncByProductId?productId='+id)
    }
}
class ProductCommentGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('ProductComment/GetAll?pageNumber='+data.pageNumber+'&count='+data.count+'')
    }
}
class ProductCommentGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('ProductComment/GetById?productCommentId='+id)
    }
}
class ProductCommentUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ProductComment/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCourseCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Course/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCourseDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Course/Delete?id='+id)
    }
}
class ProductCourseGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Course/GetAll?pageNumber='+data.pageNumber+'&searchCommand='+data.searchCommand+'&count='+data.count)
    }
}
class ProductCourseGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Course/Get?courseId='+id)
    }
}
class ProductCourseScheduleCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('CourseSchedule/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCourseScheduleDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('CourseSchedule/Delete?id='+id)
    }
}
class ProductCourseScheduleUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('CourseSchedule/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCourseUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Course/Update')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Product/Createproduct')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductDeleteProductRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Product/Deleteproduct?productId='+id)
    }
}
class ProductGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Product/GetAllNew?categoryId='+data.categoryId+'&searchByTitle='+data.searchByTitle+'&pageNumber='+data.pageNumber+'&count='+data.count+'&type='+data.type+'&isFree='+data.isFree)
    }
}
class ProductGetByIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Product/GetById?id='+id)
    }
}
class ProductUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Product/Updateproduct')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class ProductGetProductsMediasRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Product/GetProductMedias?productId='+id)
    }
}
class ProductGetProductsMediasForAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(id){
        super.setTag('Product/GetProductMediasForAdmin?productId='+id)
    }
}
class MediaDeleteByPictureUrlRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setSocialMediaId(socialMediaId){
        super.setTag('Media/DeleteByPictureUrl?socialMediaId='+socialMediaId)
    }
}
class ProductGetPackageProductsRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('Product/GetPackageProducts')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}

class ProductCategoryGetCourseCategoriesForExamRequest extends WebServiceRequest {
    constructor(context) {
        super(context)
        super.setTag('ProductCategory/GetCourseCategoriesForExams')
    }

    setParams(data) {
        super.setRequestParam(data)
    }
}
export {
    ProductCategoryCreateRequest,
    ProductCategoryDeleteRequest,
    ProductCategoryGetAllCourseCategoriesRequest,
    ProductCategoryGetAllProductCategoriesRequest,
    ProductCategoryGetAllRequest,
    ProductCategoryGetRequest,
    ProductCategoryPropertyCreateRequest,
    ProductCategoryPropertyDeleteRequest,
    ProductCategoryPropertyGetByCategoryIdRequest,
    ProductCategoryPropertyGetRequest,
    ProductCategoryPropertyUpdateRequest,
    ProductCategoryUpdateRequest,
    ProductCommentCreateRequest,
    ProductCommentDeleteRequest,
    ProductCommentGetAllAsyncByProductIdRequest,
    ProductCommentGetAllRequest,
    ProductCommentGetByIdRequest,
    ProductCommentUpdateRequest,
    ProductCourseCreateRequest,
    ProductCourseDeleteRequest,
    ProductCourseGetAllRequest,
    ProductCourseGetByIdRequest,
    ProductCourseScheduleCreateRequest,
    ProductCourseScheduleDeleteRequest,
    ProductCourseScheduleUpdateRequest,
    ProductCourseUpdateRequest,
    ProductCreateRequest,
    ProductDeleteProductRequest,
    ProductGetAllRequest,
    ProductGetByIdRequest,
    ProductUpdateRequest,
    ProductGetProductsMediasRequest,
    ProductGetProductsMediasForAdminRequest,
    MediaDeleteByPictureUrlRequest,
    ProductGetPackageProductsRequest,
    ProductCategoryGetCourseCategoriesForExamRequest
};
