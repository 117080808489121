import WebServiceRequest from '../Api/WebServiceRequest'

class ArvanCloudVideoCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ArvanCloudVideo/Create')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class DeleteArvanVideo extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('ArvanCloudVideo/Delete')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

export {
    ArvanCloudVideoCreateRequest,
    DeleteArvanVideo
}