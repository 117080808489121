import WebServiceRequest from '../Api/WebServiceRequest'

class MediaChangePriorityRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Media/ChangePriority')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class MediaChangeMediaNameRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Media/ChangeMediaName')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class MediaChangeMediaTypeRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Media/ChangeMediaType')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class MediaDeleteMediaByPictureUrlRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Media/DeleteMediaByPictureUrl')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    MediaChangePriorityRequest,
    MediaChangeMediaNameRequest,
    MediaDeleteMediaByPictureUrlRequest,
    MediaChangeMediaTypeRequest
}